@import "./assets/fonts/Avenir/stylesheet.css";

*, *::before, *::after {
    box-sizing: border-box;
}
* {
    margin: 0;
    font-family: 'AvenirNext LT Pro', sans-serif;
}
html, body {
    height: 100%;
}
body {
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    background: #F5F1EE;
}
img, picture, video, canvas, svg {
    display: block;
    max-width: 100%;
}
input, button, textarea, select {
    font: inherit;
}

p, h1, h2, h3, h4, h5, h6 {
    overflow-wrap: break-word;
}